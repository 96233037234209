.pageTitle {
    font-size: 24px;
}

.cartTableRow td {
    background: #cbdbcd;
    vertical-align: middle;
}
search-root .button.save{
    background-color:#1e4851 !important; 
}
search-root .button.cancel{
    border-radius: 2px 20px !important;
}
search-root .modal-container section p{
    color: red;
    font-size: 12px;
    margin-bottom: 10px;
}
.buy-button:hover > .button.action{
    color: #000 !important;
}
search-root .button.save:hover{
    background-color: #d3f9d8 !important;
    color: #000;
}
.btn, .buybutton, .buy-button, search-root .button.save{
    background-color:#1e4851; 
    color: #fff; 
    border: none;
    padding: 10px 20px;
    border-radius: 2px 20px !important;
    box-shadow: 5px 5px 6px #000;
    cursor: pointer;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    
}
.btn:hover, .buybutton:hover, .buy-button:hover, .button.action:hover{
    background-color: #d3f9d8;
    color: #000;
  }
  .offerbtn{
    background-color: transparent !important;
    box-shadow: none !important;
    color: #000 !important;
    justify-content: flex-start !important;
    font-weight: bold !important;
    text-decoration: underline;
    padding: 0 !important;
  }
  .offerForm label{
    padding: 10px 5px !important;
    margin: 10px 0;
  }
.phoneicon {
    width: 100px;
    height: 100px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #FFF;
    margin: auto;
    margin-top: 60px;
    margin-bottom: 60px;
    padding: 28px;
}

.phoneicon svg {
    width: 44px;
    height: 44px;
}

.numbername {
    color: #000;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
.cartamount{
    color: #000;
font-family: Roboto;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.location{
    color: #646464;
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
}
.closeicon {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    background-color: #C92B28;
    border-radius: 50%;
    margin: auto;
    display: flex;
}

.closeicon svg {
    margin: auto;
}

.price {
    color: #000;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}
@media(max-width:767px){
    .cartTableRow td {
        display: block;
        text-align-last: center;
    }
    .phoneicon{
        width: 50px;
        height: 50px;
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 10px;
    }
    .phoneicon svg {
        width: 34px;
        height: 34px;
    }
}