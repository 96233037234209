.login{
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; */
  }
  
    .logo {
    text-align: center;

  }
  
  .form-links {
    text-align: center;
    margin-top: 20px;
  }
 

  
  .button{
    background-color: #1e4851;
  }
 
  .login-pages{
    width: 100%;
    /* margin-left: 160px ;
    margin-top: -60px; */
    text-align: center;
  }
  
  .toast-success {
    background-color: #28a745; /* Green background color */
    color: #fff; /* White text color */
  }
  
  