/* IndustryNumbers.css */

.number-widget {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2); 
    border: 1px solid #ddd;
    border-radius: 8px; 
    font-family: 'Roboto';
  }
  
  .number {
    margin: 0;
  }
  
  .icon {
    margin-right: 8px;
  }
  