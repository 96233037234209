.container-color {
    background: #F9E6FF;
}

.timer {
    color: #C92B28;
    text-align: right;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
}

.addaddbtn {
    border-radius: 2px;
    background: #FFF;
}

.plusicon {
    background: #D9D9D9;
    padding: 5px;
    width: 34px;
    height: 34px;
    margin: auto;
    text-align: center;
    display: flex;
}

.addresstext {
    color: #646464;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
}

input.form-control {
    padding: 9px 27px !important;
    align-items: center;
    background-color: transparent !important;
    border-radius: 2px;
    border: 1px solid #000 !important;
    gap: 9px;
}
select.form-control {
    padding: 9px 27px !important;
    align-items: center;
    background-color: transparent !important;
    border-radius: 2px;
    border: 1px solid #000 !important;
    gap: 9px;
}