.map-container {
    width: 100%;
    height: 400px;
  }
  .Mapsection .map_image{
    display: flex;
    justify-content: center;
    margin: auto;
  }
  .Mapsection{
    background: #1e4851;
    padding-top: 30px;
  }
  .whitetext{
    color:#fff;
  }
  .mapcontainer{
    display: flex;
    align-items: center;
    padding-top: 75px;
    padding-bottom: 99px;
    position: relative;

  }
  #map{
    width: 70%;
    margin: auto;

  }
  .mapsvg{
     width: 100%; 
    margin: auto;
    display: flex;
  } 
  .state:hover{
    fill: #d3f9d8; /* Color change on hover */
  }
  .tooltip {
    position: absolute;
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    z-index: 999999999; /* Ensure the tooltip is on top of other content */
    font-size: 12px;
    /* Add more styles as needed */
  }
  @media (max-width:800px) {
    #map{
      width: 100%;
    }
  }