/* .container {
    padding: 20px;
  } */
  
  .box {
    border: 1px solid #ddd;
    border-radius: 10px; 
    padding: 20px;
    margin: 10px;
    background: #cbdbcd;
    
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  }
  
  .box-number {
    font-size: 24px;
    font-weight: bold;
  }


