/* StateNumber.css */

/* .container {
  display: flex;
  flex-direction: column;
   align-items: center; 
  justify-content: center;
   text-align: center; 
} */

h6 {
  font-size: 24px;
  margin-bottom: 20px;
}

.buttons-row {
  margin: 20px 0;
}

.local-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.toll-free-button {
  background-color: #17a2b8;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.select-row {
  margin: 20px 0;
  text-align: center;
}

.dropdown-btn {
  width: 200px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.custom-select {
  width: 200px;
}

.phone-number-widget {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
}

.phone-number {
  font-size: 16px;
}
.button-buynow{
  background-color: #1e4851;
  border-radius: 10px;
  color: #fff; 
}
.button-buynow:hover{
  background-color:#739bd0;
}
