/* Pattern.css */

.pattern-widget {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2); 
    border: 1px solid #ddd;
    border-radius: 8px;
    text-align: center;
    font-family: 'Roboto';
  }
  
  .pattern-name {
    margin: 0;
  }
  