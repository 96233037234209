 * {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.parent {
  /* margin: 20px; */
  /* align-items: center; */
  background-color: #cbdbcd;
 
}

.slider {
  width: 80%; /* Set the width to 20% of the parent container */
  margin: 0 10px; /* Add some margin between the cards */
  height: 100%;
  margin-left: 40px;
}

.slider img {
  width: 60px;
  height: 60px;
  border-radius: 50px;
}
.slider-1:hover .comment, .slider-1:hover .author{
  color: #fff !important;
}
.comment {
  color: #333;
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

.author {
  margin-top: 5px;
  font-weight: bold;
  
}
.slider-1{
 border-radius: 10px;
 height: 300px;
}
.slider-1:hover{
  color: #fff!important;
}
