.bannerSection{
    background-image: url("/public/images/banner2.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 566px;
    /* background-color: #FDA7DF; */
}
.bannercontainerbtn {
 
    max-width: 85% !important;
}
@media (min-width:1439px) and (max-width:2559px) {
    .bannerSection{
        height: 700px;
    }
    .bannercontainerbtn {
        margin-top: 80px !important;
        max-width: 85% !important;
    }
}
@media (min-width:2560px) {
    .bannerSection{
        height: 1200px;
    }
    .bannercontainerbtn{
        margin-top: 140px !important;
        max-width: 85% !important;
    }
}
.searchengnebtn {
    color: rgb(62, 132, 114);
    background-color: #cbdbcd;
    margin-top: 65px;
    font-weight: bold !important;
}

.searchengnebtn:hover, .searchengnebtn:active, .searchengnebtn:visited {
    background-color: #fff !important;
    color: rgb(62, 132, 114);
}

.bannercontainerbtn{
    margin-top: 80px;
    padding-top: 80px;
}
/* Tab to laptop */
@media (min-width:768px) and (max-width: 1023px) {
    .bannerSection{
        height: 380px;
    }
}
/* mobile */
@media (max-width: 767px){
    .bannerSection{
        height: 180px;
    }
    .bannercontainerbtn{
        padding-left:0 ;
    }
    .searchengnebtn{
        margin: 0 !important;
        padding: 5px 12px;
        font-size: 10px;
    }
}
