.option{
    border-radius: 8px 8px 0px 0px;
background: #cbdbcd;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
flex-shrink: 0;
}
.buyNowModal .card-body{
    border-radius: 0px 0px 8px 8px;
    background: #F6F6F6;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.buyNowModal .card-title{
    color: #000;
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 150% */
}
.parkingPlansBox{
    padding: 10px 30px;
    border: 1px solid #1e4851;
    border-radius: 50px;
}