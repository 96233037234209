.login-container {

  border-radius: 10px;
  padding: 10px; /* Reduced padding for spacing */
  width: 100px;
  margin-bottom: 20px;
  margin-top: 20px;
  box-shadow: 0px 7px 29px 0px #d9d9e3
}
.browersection{
  background-color: #1e4851;
  padding: 20px;
}
.browersection .card-body{
  border-radius: 0px 0px 8px 8px;
background: #F6F6F6;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
padding: 30px 20px;
}
.browersection .card-title{
  margin: 0%;
  color: #000;
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 150% */
}
.browersection svg{
  fill:#1e4851;
  width: 25px;
  height: 25px;
}

.fonts{
   font-family: 'Roboto', sans-serif;;
}
.middleheading{
  color: #000;
font-family: Roboto;
font-size: 36px;
font-style: normal;
font-weight: 600;
line-height: 44px; /* 122.222% */
text-transform: uppercase;
}
.logo {
  text-align: center;
  margin-bottom: 20px;
  width: 30%;
}

.form-wrapper {
  /* Add styles for the form wrapper as needed */
}

.form-wrapper form {
  /* Add styles for the form as needed */
}

.form-links {
  text-align: center;
  margin-top: 20px;
}

.form-links a {
  /* Add styles for the links as needed */
}

.form-links p {
  /* Add styles for the paragraph as needed */
}
