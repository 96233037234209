search-root{
    font-family: Roboto !important;
}
.shopPage{
    background-color: #cbdbcd;
}
input[type=radio]{
    width: 18px;
    height: 18px;
    border: 1px solid #1e4851;
    color: #1e4851;
}
[type="radio"]:checked+ label:after{
    background: #F87DA9 !important;
}
input{
    border:0px;
    border-bottom:1px solid #c6c6c6;
    padding: 10px 5px !important;
    margin: 10px 0;
}
input:focus-visible {
    outline:0;
}
select{
    border:1px solid #c6c6c6;
    border-radius: 5px;
    padding: 10px;
}
option{
    box-shadow: 1px 1px 3px #ccc !important;

}
/* result number boxes*/
.default #telephone-numbers > div{
    width: 100% !important;
    border:1px solid #b9c6ce;
    /* background-image: url("/public/images/bg.png"); */
    background-color: #cbdbcd;
    background-repeat: no-repeat;
    background-position: center;
    margin: 10px;
    border-radius: 16px;
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
    padding: 30px 10px !important;
    box-shadow: 3px 6px 10px #000;
}
.default #telephone-numbers > div:hover{
    box-shadow: 1px 3px 5px #ddd;
}
/* options dropdown */
.complete-options{
    box-shadow: 1px 1px 3px #ccc !important;
    border: 1px solid #b9c6ce !important;
    padding: 10px !important;
    border-radius: 5px !important;
}
/* section of results */
.default #telephone-numbers{
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns per row */
    gap: 20px; /* Adjust the gap between items as needed */
    justify-content: center !important;
    justify-items: center !important;
}
/* numbers in boxes */
.default #telephone-numbers span.telephone-number{
    width: 100% !important;
    color: #000;
}
/* price in boxes */
.default #telephone-numbers span.price{
    /* width: 100% !important; */
    color: #000;
}
/* button width */

search-root .button.action{
    /* width: 100%; */
    background:transparent !important;
    padding: 10px 20px;
    /* color: #000;
    border: 1px solid; */
    width: 150px !important;
}
/* search-root .button.action:hover{
    background: transparent !important;
    color: #1e4851;
} */
.modal-container{
    min-width: 60% !important;
}
.default #telephone-numbers span{
    padding: 0;
}
.default[_ngcontent-dwt-c1] #telephone-numbers[_ngcontent-dwt-c1] > div[_ngcontent-dwt-c1]:hover{
    background-color: transparent !important;
    border-color: #1e4851;
}

/* Additional styling for the image if needed */
.sticky-image img {
    position: fixed;
    z-index: 9;
    box-shadow: 0 0 0px 0 rgb(0 0 0/40%);
    top: 20%;
    right: -95px;
}
search-root .modal-container header{
    background-color: #cbdbcd !important;
    color: #000 !important;
    
}
h2.telephone-number{
    margin: 20px;
    font-family: Roboto;
}
.modal-container[_ngcontent-jiq-c4] > button.close[_ngcontent-jiq-c4]{
    color: #000 !important
}
search-root modal-container label{
    font-family: Roboto;
    font-size: 14px;
    color: #000;
    text-align:left;
}
.modal-container[_ngcontent-jiq-c4]{
    min-width: 70%;
}

@media (max-width:1023px){
    .default #telephone-numbers{
        display: block !important;
    }
    search-root .button.action{
        width: 90px !important;
    }
}