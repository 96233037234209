.connectedSection {
    background-color: #f4f4f4;
  }

  .cursor-pointer{
    cursor: pointer;
  }
  .subscription{
    padding: 9px 14px;
  }
  
  .connectedSection hr {
    border-top: 1px solid #ddd;
  }
  
  .connectedSection h4 {
    color: #333;
  }
  
  .connectedSection p {
    color: black;
  }
  
  .connectedSection .btn-dark {
    background-color: #343a40;
    border-color: #343a40;
  }
  
  .connectedSection .btn-dark:hover {
    background-color: #292b2c;
    border-color: #292b2c;
  }
  

  
 
  
  /* Modal Style */
  .modal-content {
    background-color: #fff;
  }
  
  
  .modal-footer {
    background-color: #f4f4f4;
  }
 
  
  /* Add your existing styles here */

.custom-email-input {
    /* Add styles for the email input */
    margin-right: 100px; /* Adjust the right margin as needed */
  }
  
  .custom-subscribe-btn {
    /* Add styles for the Subscribe button */
    margin-left: 100px; /* Adjust the left margin as needed */
  }
  
  /* Add any other styles you need */
  
  