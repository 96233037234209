/* State.css */

/* Add some general styling for the page layout */
.statesPage {
  margin: 0;
  background-color: #f4f4f4;
  padding: 20px;
}

/* Style for the state card */
.state-card {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  margin: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.state-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Style for the image container */
.image-container {
  overflow: hidden;
}

.image-container img {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #ddd; /* Optional: Add a border between image and state info */
}

/* Style for the state information */
.state-info {
  padding: 15px;
}

.state-name {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
  white-space: nowrap;
}

.area-code {
  font-size: 16px;
  color: #555;
}

/* Style for the header */
.text-center h2 {
  color: #333;
}

/* Responsive styles for smaller screens */
@media (max-width: 767px) {
  .state-card {
    margin: 15px 0;
  }
}
