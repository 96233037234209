
.my-profile-container .input {
    border: 0px;
    padding: 10px 5px !important;
    margin: 10px 0;
    width: 100%;
  }
  
  .input-title {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .profile-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  
  .form-group .input-title {
    margin-bottom: 5px;
  }
  
  .input-title {
    font-weight: bold;
  }
  
  
  
  table.ordersTable {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1em;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 15px;
    text-align: left;
    font-size: 14px;
  }
  
  th {
    background-color: #cbdbcd;
  }

  /* Mobile responsiveness */
@media (max-width: 767px) {
  .table-responsive {
    overflow-x: auto;
  }

  .ordersTable th,
  .ordersTable td {
    font-size: 14px; /* Adjust font size for smaller screens */
  }

  /* Add any other mobile-specific styling as needed */
}
