.demand-supply-section {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }
  .demand-supply-container{
    background-color: #f4f4f4;
  }
  
  .section-title {
    color: #333;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  p {
    color: #666;
    font-size: 1rem;
    line-height: 1.5;
  }
  
  .highlight-text {
    background-color: #f8f8f8;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  