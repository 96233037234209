/* Aboutus.css */
.about-us-content {
    max-width: 80%;
    margin: 0 auto;
    background-color: #f8f8f8;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px; 
  }
  
  .about-us-content h2 {
    color: #333;
  }
  
  .about-us-content hr {
    margin: 20px 0;
    border: 0;
    height: 1px;
    background-color: #ccc;
  }
  
  .about-us-content .card {
    margin-bottom: 20px;
  }
  
  .about-us-content h3 {
    color: #555;
  }
  
  .about-us-content p {
    color: #666;
  }
  
  .about-us-content p:last-child {
    margin-bottom: 0;
  }
  
  .nd{
    background-color: #f4f4f4;
  }