/* UserProfile.css */

.profile-container {
    display: flex;
    justify-content: space-between;
    margin: 20px;
  }

.UserDataCon {
    border: 0.2px solid #646464;
    padding: 10px;
}
.UserData-nav svg {
  margin-right: 5px;
}
.usernameCon {
    background: #F7F7F7;
    vertical-align: middle;
    padding: 15px;
    border-radius: 8px;
}
.username {
    font-family: Helvetica;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
}
.UserData-nav {
    background-color: #cbdbcd;
    border-radius: 8px;
    display: flex;
    align-items: center;
}

.UserData-nav p{
    background-color: #cbdbcd;
    color: #000000;
    padding: 0px;
    cursor:pointer;
}
.userDiv{
  background-color: #cbdbcd;
  padding: 25px;
  border-radius: 15px;
}

  .side-nav {
    display: flex;
    flex-direction: column;
  }
  
  .button-nav button {
    margin-bottom: 10px;
    width: 80%; /* Make buttons take full width */
    border-radius: 6px; /* Apply border-radius */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  }
  
  .content {
    flex: 1;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 0 20px;
    border-radius: 10px; /* Apply border-radius to content */
  }
  
  .address-box {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px; 
  }
  
  .address_box {
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 15px;
}

    .address_box:hover {
        border: 1px solid #000;
    }
@media (max-width:767px){
  .UserData-nav{
    width: 49%;
    margin:1px !important;
  }
  .content{
    margin: 20px 0 !important;
  }
}