.small-icon-image {
    max-width: 40px; /* Adjust the size as needed */
    max-height: 40px; /* Adjust the size as needed */
}
.whysection{
    background-color: #cbdbcd;
    padding: 20px;
    
}
.card-title {
    font-size: 1.2rem; /* Adjust the font size as needed */
}

.card p {
    font-size: 1rem; /* Adjust the font size as needed */
    margin-top: 10px; /* Add spacing between the title and text */
}

.karmaheading{
    color: #000;
font-family: Roboto;
font-size: 36px;
font-style: normal;
font-weight: 500;
line-height: 58px; /* 161.111% */
text-transform: uppercase;
}