.topbar{
    background-color: #000;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.topbar p{
    color: #FFF;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
}
/* menubar */
.menubar{
    background: #FFF;
    height: 60px;
}
.navbar{
    display: flex;
    justify-content: space-around;
    align-items: center !important;
}
.nav-link{
    color: #000 !important;
    font-family: Roboto !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400 !important;
    /* line-height: 44px !important;  */
}
.nav-link:hover{
    border-bottom: 2px solid #1e4851;
    background: #FFF;
}
.sideheading{
    color: #000;
font-family: Roboto;
font-size: 18px;
font-style: normal;
font-weight: 500 !important;
line-height: 28px; /* 155.556% */
padding: 0;
text-transform: uppercase !important;
}
.countnumber{
    font-size: 10px;
    background: #000;
    width: 20px;
    height: 20px;
    line-height: 1.7;

    border-radius: 50%;
    text-align: center;
    position: absolute;
    top: -3px;
    right: -10px;
    display: flex;
    color: #fff;
    justify-content: center;
    align-items: center;
}
.carticon{
    position: relative;
}
.mega-menu.onsale{
    position: inherit;
}
.mega-menu.onsale h6{
    font-size: 18px;
    color: #1e4851;
}
/* .navbar-nav .dropdown-menu {
    background-color: #cbdbcd;
} */
.mega-menu.onsale .dropdown-menu{
    min-width: 45% !important;
    padding-top: 20px;
    margin-top: -10px;
    left: auto;
}
.mega-menu.onsale .dropdown-item{
    white-space:inherit;
    padding: 10px;
    width: 100%;
}
.dropdown-item.active, .dropdown-item:active, .dropdown-item:hover{
    color: #fff !important;
    font-weight: 600 !important;
    background-color: #1e4851 !IMPORTANT;
}
.dropdown-menu.show{
    background: #cbdbcd;
}
.Usericon{
    font-size:25px;
}
.sticky-image {
    position: fixed;
    z-index: 9;
    box-shadow: 0 0 0px 0 rgb(0 0 0/40%);
    top: 20%;
    right: -95px;
}

.sticky-header {
    position: sticky;
    top: 0;
    width: 100%;
    background-color: #fff; /* Adjust background color as needed */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle box shadow */
    z-index: 1000; /* Adjust z-index to ensure it's above other elements */
  }

  
@media(max-width:800px){
    .carticon{
        width: 40px;
    }
    .navbar-collapse {
        background-color: #fff;
        padding: 10px 20px;
        z-index: 99999;
        /* height: 400px !important;
    overflow-y: scroll; */
    }
}
