.process-container {
  max-width: 800px;
  margin: 0 auto;
 
}
.processcontainer{
  background-color: #f4f4f4;
  font-family: 'Arial', sans-serif;
}

.process-step {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 20px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.process-step:hover {
  transform: scale(1.05);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

h3 {
  color: #333;
  font-size: 1.5rem;
  margin-bottom: 10px;
}

p {
  color: black;
  font-size: 1rem;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 5px;
}

