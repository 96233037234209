.digital-cold-storage-section ul {
    list-style-type: disc; 
    color: #333; 
    margin-left: 20px; 
}

.digital-cold-storage-section li {
    color: #333; 
    margin-bottom: 8px; 
    font-family: sans-serif;
}
.digital-cold-storage-section-container{
    background-color: #f4f4f4;
}
