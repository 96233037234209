/* LocalNumber.css */

.localNumbersPage {
  /* Add your styles for the LocalNumber component */
}

.local-industry-card {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-bottom: 20%;
}

.local-image-container {
  /* height: 200px; */
  overflow: hidden;
}

.local-image-container img {
  width: 100%;
  height: 100%;
}

.local-number {
  background-color: #fff;
  color:  #333;
  padding: 10px;
  margin: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 18px;
  font-weight: bold;

}
