.supportSection {
    display: grid;
}

.supportSection h4 {
    color: #000;
    font-family: Roboto;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
    /* 122.222% */
    text-transform: uppercase;
}

.support {
    padding: 20px 15px;
}



.support p {
    color: #000;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;

    margin: 0;
}

.support Button {
    border-radius: 2px;
    border: 1px solid #000;
    padding: 9px 27px;
    color: #000;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    background-color: transparent;
    margin: 29px 0;
    margin-left: 20px;
}
/* .secondcolumn Button{
    width: 100%;
} */