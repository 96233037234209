.footer {
    background: #1e4851;
    padding-top: 50px;
}

.footer_menu {
    display: flex;
    justify-content: space-between;
}

.footerbelow_menu {
    display: flex;
    justify-content: flex-start;
}

.end-item {
    display: flex;
    justify-content: flex-end;
}

.middleSection {
    border-bottom: 1px solid #cdcdcd;
}

.copyrights {
    padding: 18px 27px;
}

.copyrights li {
    color: #cdcdcd;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin: 0;
    padding: 0 9px;
}

.rightborder {
    color: #cdcdcd;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
}

@media (max-width:700px) {

    .footer_menu,
    .footerbelow_menu,
    .end-item {
        display: block;
    }

    .end-item {
        padding: 14px 26px !important;
    }

    .rightborder {
        display: none;
    }
}

h6 {
    margin-bottom: 2px;
}

ul {
    padding-left: 0px !important;
    list-style: none;
}

.footer li {
    color: #FFF;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    margin: 20px 0;
    text-decoration: none;
}

.footer span {
    color: #cdcdcd;
    margin-left: 10px;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    /* 155.556% */
}

.payment_box {
    padding: 14px;

}

.copyrights ul {
    display: flex;
    margin: 0;
}
.footer h6{
    color: #fff;
}

.social-icons a {
    margin-right: 20px; 
    color: #fff;
  }
  .copyright p {
    color: #fff;
    margin: 0;
    margin-top: 10px;
  }
  
  /* Add this to your existing CSS file (Footer.css) */
.social-icons a:hover .fa-facebook {
    color: #1877F2;
  }
  
  .social-icons a:hover .fa-twitter {
    color: #1DA1F2;
  }
  
  .social-icons a:hover .fa-instagram {
    color:  #8a3ab9;
  }
  
  .social-icons a:hover .fa-linkedin {
    color: #0A66C2;
  }
  .social-icons a:hover .fa-youtube {
    color: #CD201F;
  }
  .social-icons a:hover .fa-pinterest {
    color: #E60023;
  }
  li a {
    text-decoration: none; 
    color: #FFF;
  }
  