/* Customnumbers.css */

body {
    font-family: 'Arial', sans-serif;
    color: #333;
  }
  .customnumbers{
    background-color: #f4f4f4;
  }
  
  .container {
    /* max-width: 1200px; */
    margin: 0 auto;
  }
  
  .table-section {
    margin-top: 20px;
  }
  
  h1 {
    color: #333;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  .table th, .table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  /* Table header background color */
  .table th {
    background-color:rgb(14, 236, 236);
    color: #fff;
  }
  
  /* Alternate row background color for better readability */
  .table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .content-section {
    margin-top: 20px;
  }
  
  .content-section p {
    margin-bottom: 10px;
  }
  