/* SideNav.css */

/* Set a maximum height for the accordion body and add a vertical scrollbar */
.accordion-body-scrollable {
    max-height: 200px; /* Adjust the value as needed */
    overflow-y: auto;
  }
  .accordion-button:not(.collapsed){
    background-color: #cbdbcd;
    color: #000;
  }
  .accordion-button:focus{
    border-color: #1e4851 !important;
  }