.bg-light:hover {
    background-color:#1e4851 !important; 
    color: white !important; 
    padding: 20%;
  }

  .tab {
    transition: background-color 0.3s, color 0.3s; 
    cursor: pointer; 
    user-select: none; 
    overflow: hidden; 
    position: relative; 
    border-radius: 8px;
    border: 1px solid #1e4851;
  }
  .tab i{
    margin-right: 10px;
  }
  .tab:hover {
    background-color: #1e4851;
    color: white; 
  }
    .tab span {
      color: #646464;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
  }
  
  .tab:hover span{
    color: white; 

  }
  
  
  
  
  