.porting-container {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .portingcontainer{
    background-color: #f4f4f4;
  }
  
  .porting-item {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 20px;
    transition: transform 0.3s ease-in-out;
  }
  
  .porting-item:hover {
    transform: scale(1.02);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
  
  h3 {
    color: #333;
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  p {
    color: #666;
    font-size: 1rem;
  }
  

 
  